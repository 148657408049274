export const contactData = {
  current: {
    phoneNumber: '0346 - 250 171',
    emailAddress: 'info@mogelijk.nl',
    photo: '/rozalinda.jpg',
  },
  funds: {
    phoneNumber: '0346 - 264 664',
    emailAddress: 'fondsbeleggen@mogelijk.nl',
    photo: '/antoinette.jpeg',
  },
  default: {
    phoneNumber: '0346 - 250 171',
    emailAddress: 'info@mogelijk.nl',
    photo: '/brechtje.jpeg',
  },
};
